.simple-keyboard.myTheme1 {
  background-image: linear-gradient(to right, #ebb39a, #cfc7f8);
  border-radius: 5px;
}

.simple-keyboard.myTheme1 .hg-button {
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fdfcfb;
  color: #667184;
  font-weight: bold;
}

.simple-keyboard.myTheme1 .hg-button:active {
  background: #f4c9a8;
  color: white;
}

.simple-keyboard.myTheme1 .hg-button.hg-standardBtn.not-in {
  background: #d4dde9;
}

.simple-keyboard.myTheme1 .hg-button.not-in:active {
  background: #c1ccda;
  color: white;
}
